.p_login {
  display: flex;
  width: 100%;
  min-height: 760px;
  background-color: #f7f7f7
}
.log_col1 {
  width: 70%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

img.login_bg {
  width: 98%;
  height: 100%;
}
.log_col2 {
  width: 30%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.login_button {
  background-repeat: no-repeat;
  border-radius: 5px;
  margin-top: 50px;
  cursor: pointer;
}

h2.log_sub {
  color: #4476bd;
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 400;
}
h1.log_name {
  color: #4476bd;
  font-size: 34px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0px;
}

p.log_p1 {
  color: #fff;
  margin-left: 139px;
  margin-top: -36px;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
  margin-left: 58px;

}

img.login_but {
  width: 100%;
  height: 38px;
}
