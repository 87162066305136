/*
#9D81FF - p small name
*/
.profile_box {
  display: flex;
}

.dash_width {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.pro_col1 {
  margin: 3px 11px;
  display: flex;
}
.pro_col2 {
  width: 90px;
  margin: 12px;
  margin-top: 20px;
}
h1.pro_name {
  font-size: 14px;
  margin: 0px;
  text-transform: capitalize;
  width: 150px;
}
p.d1_p1 {
  font-size: 9px;
  margin: 1px;
  color: #9d81ff;
}
.dash1_head {
  margin-top: 26px;
}
.pro_col3 {
  margin-top: 30px;
  margin-left: 50px;
}
p.back {
  color: #9d81fc;
  text-transform: capitalize;
  font-size: 13px;
  margin-left: 10px;
}

h1.dash1_head_name {
  text-transform: capitalize;
  color: #8258ed;
  font-size: 18px;
  font-weight: 400;
}
.add_tl_col3 {
  width: 156px;
}

.launch_row3,
.s_t_inner_row,
.set_inner_row1,
.add_tl_col2,
.d1_row41,
.content_row1,
.pro_col3,
.dash1_row3,
.d1__row2,
.d_date1,
.d_date,
.d1_row11,
.d1_col5,
.d1_row1,
.dash1_row2 {
  display: flex;
}

.d1_col1 {
  width: 85%;
  padding: 20px;
}
.d1_col2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  width: 18%;
  padding: 20px 15px;
  min-height: 638px;
}
.d1_card {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
}

p.d1_p3 {
  margin: 3px;
  font-size: 10px;
  color: #5c5c5c;
}
p.d1_p2 {
  color: #8258ed;
  font-size: 12px;
  margin: 1px;
}
.d1_col7 {
  margin-top: 6px;
  margin-left: 10px;
}

.d1_col3 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  display: flex;
  height: 20px;
  padding: 10px;
}
.d1_col8 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  height: 20px;
  padding: 10px;
  width: 78px;
  box-shadow: 7px 6px 9px #eeeeee;
  display: flex;
  justify-content: center;

}
.f_arrow {
  height: 80%;
  width: 100%;
}
.location_card {
  position: absolute;
  z-index: 5;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e5e5;
  border-top: none;
  padding: 0 9px;
  margin-left: -11px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 80px;
  text-align: left;
  box-shadow: 7px 7px 11px #eeeeee;

}
p.d1_p5 {
  font-size: 11px;
  color: #a2a2a2;
  margin-left: 5px;
}
img.drop_down {
  margin-left: 6px;
  margin-bottom: 1px;
}
img.hr_line {
  width: 18px;
}

p.d1_p4 {
  font-size: 12px;
  margin: 0;
}

.d1_col8 {
  margin-left: 20px;
}
.d1_row1 {
  justify-content: space-between;
}

.d1_col22,
.d1_col21 {
  width: 50%;
  padding: 15px;
}
p.d1_p21 {
  margin: 0;
  color: #db23fb;
  font-size: 14px;
}

p.d1_p22 {
  font-size: 9px;
  margin: 0px;
  margin-top: 5px;
}

.d1_card1 {
  width: 30%;
}
img.img_d1_card {
  width: 100%;
}

.d1_col22 {
  display: flex;
  justify-content: space-between;
}

.d1_small_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  padding: 10px;
  width: 15%;
  text-align: center;
  color: #9f9f9f;
  cursor: pointer;
}

.d1_small_card1 {
  background: #9d81fc 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #ffff;
  border: 1px solid #d5d5d5;
  padding: 10px;
  width: 15%;
  text-align: center;
  cursor: pointer;
}



p.d1_p31 {
  font-size: 16px;
  margin: 3px;
}

.content {
  position: absolute;
  margin-top: -100px;
  padding: 10px;
  text-align: center;
  height: 80px;
  min-width: 143px;
}
p.d1_p23 {
  font-size: 11px;
  color: #fff;
  margin: 8px 20px 1px;
}

p.d1_p24 {
  font-size: 16px;
  color: #fff;
  margin: 4px 4px 8px;
  font-weight: 600;
}
p.d1_p25 {
  font-size: 11px;
  color: #fff;
  margin: -3px;
}

p.d1_p26 {
  font-size: 10px;
  margin: 11px 18px 2px;
  color: #fff;
}
p.d1_p27 {
  font-size: 14px;
  margin: -4px;
  font-weight: 600;
}

.d1_number {
  background: #e8e8e8;
  border-radius: 50%;
  padding: 3px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;

  color: #575757;
}

.d1_number1 {
  background: #18aa1b 0% 0% no-repeat padding-box;
  border-radius: 50%;
  padding: 3px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;

  color: #ffffff;
}
.d1_number2 {
  background: #ff4d4d 0% 0% no-repeat padding-box;
  border-radius: 50%;
  padding: 3px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;

  color: #ffffff;
}

p.d1_p41 {
  font-size: 12px;
  margin: 5px 8px;
  color: #9f9f9f;
}

.d1_row4 {
  display: flex;
  margin: 20px 12px;
  cursor: pointer;
}
img.rupee_svg {
  margin-bottom: 6px;
}
.content_d {
  display: flex;
  justify-content: center;
}

.add_tl_card {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #c1c1c180;
  border-radius: 10px;
  padding: 14px;
}

p.d1_p411 {
  margin: 2px 10px;
  font-size: 12px;
  font-weight: 600;
  color: #18aa1b;
}
.d1_row42 {
  margin: 12px 0;
}

.buttons1 {
  background: #f0edfa 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: center;
  color: #8258ed;
  font-size: 10px;
  padding: 7px 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons2 {
  background: #9d81ff 0% 0% no-repeat padding-box;
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 5px;
  padding: 7px 9px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 20px;
  margin-left: 15px;
  justify-content: center;
  min-width: 80px;
}
.d1_row43 {
  display: flex;
  justify-content: space-between;
}

img.add_img {
  height: 12px;
  width: 12px;
  margin: 2px 0px;
}
p.d1_p42 {
  margin: 0;
  margin-left: 5px;
}
.add_tl_card1 {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 20px 40px;
  margin-bottom: 20px;
}
.add_tl_row1 {
  display: flex;
  justify-content: space-between;
}

h1.add_tl_col1 {
  color: #db23fb;
  font-weight: 400;
  margin: -5px 30px;
  font-size: 17px;
}
.add_tl_search {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b3b3b3d9;
  border-radius: 5px;
  display: flex;
  padding: 4px;
  width: 250px;
}
img.search_svg {
  width: 16px;
  height: 20px;
  margin: 0px 20px;
}

p.add_tl_p1 {
  margin: 2px;
  font-size: 11px;
  color: #6210e1;
}

.add_tl_card2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #dedede66;
  border-radius: 5px;
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
  padding: 20px;
}

.add_tl_card2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #dedede66;
  border-radius: 5px;
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
  padding: 12px;
}

.add_tl_p8,
.add_tl_p4,
.add_tl_p5,
.add_tl_p6,
.add_tl_p7,
p.add_tl_p2 {
  margin: 0;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}

.add_tl_col1 {
  padding: 0 25px;
}
p.add_tl_p3 {
  font-size: 10px;
  text-align: center;
  color: #929292;
  margin: 0;
  margin-top: 4px;
}

p.add_tl_p4 {
  color: #66e4b6;
}

p.add_tl_p5 {
  color: #732de5;
}

p.add_tl_p6 {
  color: #e946df;
}
p.add_tl_p7 {
  text-align: center;
  background: #d1eed1;
  border-radius: 5px;
  color: #18aa1b;
  padding: 10px 20px;
}
p.add_tl_p8 {
  text-align: center;
  background: #feedd5;
  border-radius: 5px;
  padding: 10px 20px;
  color: #fba42d;
}

.set_date_row3,
.add_tl_row3 {
  text-align-last: end;
  margin: 25px;
}

input.up_t_save_button,
input.add_tl_save_button {
  border: none;
  background: #9d81ff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px 40px;
  color: #fff;
  cursor: pointer;
}
input.run_pay_button {
  border: none;
  background: #b9b9b9 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px 40px;
  color: #fff;
}

/* .update_train_card {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 400px;
} */

/* .up_train_inner_card {
  background: #fff;
  border: 1px dashed #a0a0a0;
  border-radius: 5px;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  align-content: center;
  align-items: center;
} */

p.up_t_p1 {
  margin: 8px;
  font-size: 12px;
  font-weight: 500;
}

p.up_t_p2 {
  font-size: 8px;
  color: #929292;
  margin: 0;
}
/* img.document_svg {
  width: 35px;
  height: 35px;
} */

.set_train_row1 {
  display: flex;
  justify-content: center;
}

.setup_trainer_Card {
  background: #ffffff;
  border: 1px solid #323c461a;
  width: 85%;
  padding: 10px;
}
p.set_t_p1 {
  color: #db23fb;
  margin: 0;
  font-size: 15px;
  margin-left: 20px;
}

p.set_t_p2 {
  margin: 5px;
  width: 15px;
}

.set_t_inner_card {
  background: #fafafa;
  padding: 10px;
  width: 85%;
  margin-top: 20px;
  margin-left: 40px;
  border-radius: 8px;
}
input#question2,
input#question {
  height: 30px;
  padding: 1px 15px;
  width: 75%;
  margin: 4px;
  border-radius: 2px;
  border: 1px solid #575757;
}
input#question1 {
  height: 30px;
  width: 100%;
  margin: 4px;
  border-radius: 2px;
  border: 1px solid #575757;
  padding: 5px 15px;
}

input#question2::placeholder {
  color: #9d81fc;
}

.set_t_p21 {
  margin: 5px;
}
.set_done {
  text-align-last: end;
  width: 91%;
}

input.set_done_button {
  background: #edf2f2 0% 0% no-repeat padding-box;
  border: 1px solid #2e7d32;
  border-radius: 2px;
  color: #2e7d32;
  padding: 5px 15px;
  margin-top: 15px;
}
.set_add_q {
  margin-left: 40px;
}
input.set_add_q_button {
  background: #f6f2ff 0% 0% no-repeat padding-box;
  border: 1px solid #8258ed;
  border-radius: 5px;
  padding: 5px 15px;
  color: #8258ed;
  margin-bottom: 15px;
}
.s_t_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #575757;
  border-radius: 2px;
  font-size: 12px;
  padding: 6px;
  margin: 10px;
}

.s_t_small_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c7c7c7c9;
  border-radius: 2px;
  margin: 10px 0px;
  width: 25%;
}
.set_inner_row3 {
  display: flex;
  flex-wrap: wrap;
}
.s_t_small_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c7c7c7c9;
  border-radius: 2px;
  margin: 10px;
  padding: 5px 10px;
  width: 45%;
  font-size: 12px;
}
.set_inner_row4 {
  margin-top: 35px;
  margin-left: 20px;
}
.buttons3 {
  background: #f0edfa 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: center;
  color: #8258ed;
  font-size: 11px;
  padding: 10px 19px;
  cursor: pointer;
}
.buttons4 {
  background: #9d81ff 0% 0% no-repeat padding-box;
  color: #fff;
  text-align: center;
  font-size: 11px;
  border-radius: 5px;
  padding: 10px 10px;
  cursor: pointer;
}

.d1_p412 {
  font-size: 12px;
  margin: 5px 8px;
  color: #ff4d4d;
}

.buttons5 {
  background: #9d81ff 0% 0% no-repeat padding-box;
  color: #fff;
  text-align: center;
  font-size: 11px;
  border-radius: 5px;
  padding: 8px 10px;
  cursor: pointer;
  width: 50%;
}
div.update_next_button {
  display: flex;
  justify-content: flex-end;
}

.d1_update_card {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  margin-bottom: 80px;
}

.d1_up_col1 {
  width: 80%;
}
p.d1_up_p1 {
  font-size: 12px;
}

.d1_up_col2 {
  display: flex;
  width: 20%;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.d1_req_train {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #8258ed;
  border-radius: 5px;
  padding: 5px 15px;
}
p.d1_req_p1 {
  font-size: 12px;
  color: #9d81fc;
  margin: 0;
}
.set_date_inner_card {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 94%;
  margin: AUTO;
  padding: 20px;
  height: 180px;
  text-align: start;
}
p.set_date_p1 {
  color: #db23fb;
  font-size: 14px;
  margin: 12px 0;
}
.set_date_row2 {
  display: flex;
}
.set_date_inner_card2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 10px;
  margin-right: 30px;
  width: 35%;
}
p.set_date_p2 {
  color: #5c5c5c;
  margin: 0 10px;
  font-size: 13px;
}

input#date {
  background: #efefef 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  padding: 6px 15px;
  margin: 18px 8px;
  width: 84%;
  color: black;
  height: 25px;
  font-size: 12px;
}

.set_date_row3 {
  margin-right: 10%;
}
/* .run_payroll_total,
.launch_project_total {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
} */
p.lch_p1 {
  color: #db23fb;
  margin: 10px 0;
  font-size: 15px;
}
.launch_row2 {
  margin: 0 20px;
  font-size: 10px;
}

.launch_row1 {
  margin: 0 20px;
}

.lch_col1 {
  width: 70%;
}
.lch_col2 {
  width: 30%;
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
}
.payroll_button {
  border: none;
  background: #9d81ff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px 25px;
  color: #fff;
  cursor: pointer;
}
p.up_re_p2 {
  font-size: 10px;
  margin: 10px;
}
p.up_re_p1 {
  color: #db23fb;
  margin: 0 10px;
}
.upload_report_card {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px 30px;
}
.upl_re_row2 {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.project_detail_card {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  margin: 30px 0;
  padding: 20px 35px;
}
.proj_d_row1 {
  margin: 15px 0;
  margin-bottom: 30px;
}
.my_team_data {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 20px;
  margin: 30px 0;
}
p.my_t_p1 {
  font-size: 16px;
  margin: 10px;
  color: #db23fb;
}
.my_t_row1 {
  display: flex;
  justify-content: space-between;
  width: 99%;
}
.my_t_row2 {
  margin: 10px;
}
.my_t_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 15px 80px #dedede65;
  border-radius: 15px;
  padding: 10px 20px;
  margin-bottom: 20px;
}
img.prof_img {
  height: 50px;
  width: 50px;
}

p.my_t_p5,
p.my_t_p4,
p.my_t_p2 {
  margin: 5px;
  font-size: 12px;
  margin-bottom: 0;
}
p.my_t_p2 {
  font-weight: 600;
}
p.my_t_p3 {
  font-size: 9px;
  margin: 0;
}
p.my_t_p4 {
  color: #5c5c5c;
}

p.my_t_p5 {
  color: #1ea056;
  font-weight: 600;
}

p.my_t_p10,
p.my_t_p9,
p.my_t_p8,
p.my_t_p6 {
  font-size: 15px;
  margin: 5px;
  font-weight: 500;
  margin-bottom: 0px;
}
p.my_t_p6 {
  color: #5524fb;
}

p.my_t_p11,
p.my_t_p7 {
  font-size: 9px;
  font-weight: 500;
  margin: 0;
}
p.my_t_p11 {
  color: #d87be8;
}
p.my_t_p9 {
  color: #36ba96;
}
p.my_t_p8 {
  color: #db23fb;
}
p.my_t_p10 {
  color: #7e5bf8;
}

.my_t_col1,
.my_t_col2,
.my_t_col3,
.my_t_col4,
.my_t_col5,
.my_t_col6,
.my_t_col7,
.my_t_col8,
.my_t_col9 {
  text-align: center;
  margin: 5px;
  min-width: 92px;
  flex: 1;
}

img.cal_svg {
  margin-left: 10px;
  cursor: pointer;
}

.my_statics_progress_bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #24e0e0;
  transition: width 0.6s ease;
}
.my_statics_data {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 20px;
  margin: 30px 0;
}
.my_stats_row1 {
  display: flex;
}

img.my_stat_card {
  width: 190px;
  height: 110px;
  margin: 10px;
}

.my_stats_card1 {
  position: absolute;
  margin-top: -125px;
  text-align: center;
  margin-left: 55px;
}
p.my_stats_p1 {
  color: #fff;
  font-size: 17px;
  margin: 0;
  margin-top: 22px;
}
p.my_stats_p2 {
  color: #fff;
  margin: 3px;
  font-size: 18px;
}
img.my_stat_card1 {
  width: 140px;
  height: 100px;
  margin: 16px;
}
.my_stats_card2 {
  position: absolute;
  margin-top: -116px;
  margin-left: 12px;
  text-align: center;
  width: 150px;
}
p.my_stats_p3 {
  color: #fff;
  margin: 0;
  margin-top: 20px;
  font-size: 12px;
}
p.my_stats_p4 {
  color: #fff;
  margin: 1px;
}

.my_stats_col1 {
  width: 70%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 15px 40px #dedede7e;
  border-radius: 15px;
  min-height: 300px;
  margin: 10px;
  margin-right: 15px;
}

.my_stats_col2 {
  width: 30%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 15px 40px #dedede7e;
  border-radius: 15px;
  min-height: 300px;
  margin: 10px;
  margin-left: 15px;
}
.my_stats_row2 {
  display: flex;
}
p.my_stats_p5 {
  color: #db23fb;
  margin-left: 15px;
}
.my_stats_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 7px 40px #dedede1a;
  border-radius: 15px;
  margin: 15px;
  padding: 20px;
  display: flex;
}
.my_s_col0 {
  margin: 20px 0px;
}

.my_s_col1 {
  width: 120px;
  margin: 0 10px;
  text-align: center;
  margin-top: 25px;
}

.my_s_col2 {
  min-width: 85px;
  margin: 0 10px;
  text-align: center;
  margin-top: 25px;
}

p.my_s_p5 {
  font-size: 13px;
  font-weight: 600;
  margin: 1px;
}
p.my_s_p6 {
  font-size: 9px;
  margin: 0;
  color: #9f9f9f;
}
p.my_s_p7,
p.my_s_p8,
p.my_s_p9 {
  margin: 1px;
  font-size: 13px;
  font-weight: 500;
}
p.my_s_p8 {
  color: #9d81fc;
}
p.my_s_p9 {
  color: #1ea056;
}
.my_s_col3 {
  width: 42%;
  margin-top: 10px;
}
.my_stats_row5 {
  display: flex;
}
.my_statics_progress {
  display: flex;
  overflow: hidden;
  font-size: 0.60975rem;
  background-color: #eee;
  width: 100%;
  height: 12px;
  border-radius: 20px;
  margin: 15px;
}
p.my_s_p10 {
  font-size: 12px;
  width: 38%;
  margin: 12px 2px;
}
.my_t_c_row3,
.my_t_c_row1 {
  display: flex;
}

.my_t_c_col1 {
  width: 50%;
}
.my_t_c_row4 {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px;
  margin: 15px;
  margin-bottom: 30px;
}
p.my_t_c_p1 {
  margin: 0;
  color: #673995;
  font-size: 13px;
}
.my_t_c_box5,
.my_t_c_box4,
.my_t_c_box3,
.my_t_c_box2,
.my_t_c_box1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
}

.my_t_c_box1 {
  border: 1px solid #10be4a;
  color: #0e9a11;
}
p.my_t_c_p2 {
  margin: 0;
  font-size: 12px;
}
.my_t_c_boxes {
  display: flex;
  margin: 8px 0;
}

.my_t_c_box2 {
  border: 1px solid #ff4f3f;
  color: #f83826;
  margin-left: 15px;
}
.my_t_c_box3 {
  border: 1px solid #9d81fc;
  color: #5524fb;
  position: absolute;
  margin-left: 290px;
}
p.my_t_c_p3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}
.my_t_c_box4 {
  background: #9d81fc41 0% 0% no-repeat padding-box;
  border: 1px solid #9d81fc;
  border-radius: 5px;
  color: #5524fb;
  width: 35%;
}

.my_t_c_box5 {
  background: #fba42d27 0% 0% no-repeat padding-box;
  border: 1px solid #fba42d;
  border-radius: 5px;
  width: 35%;
  color: #fa8700;
  margin-left: 50px;
}
.my_t_c_col2 {
  width: 50%;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 10px 15px 80px #dedede1a;
  border-radius: 15px;
  margin: 15px;
  margin-bottom: 30px;
}
.my_t_c_card {
  display: flex;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.my_t_row4 {
  margin: 5px 0;
}

.my_t_col11,
.my_t_col12,
.my_t_col13,
.my_t_col14,
.my_t_col15,
.my_t_col16,
.my_t_col17,
.my_t_col18,
.my_t_col19 {
  text-align: center;
  margin: 5px;
  min-width: 94px;
}
.setting_data {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 20px;
  margin-top: 30px;
}
.setting_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
}
.setting_row1 {
  display: flex;
  justify-content: space-between;
}
p.setting_p1 {
  color: #252733;
  font-weight: 500;
  margin: 5px;
}

img.edit_pro {
  width: 30px;
  height: 30px;
}
.my_t_row01 {
  margin-left: 5px;
}
.my_t_col01 {
  display: flex;
  width: 9%;
  text-align: center;
}
p.my_tl_p01 {
  margin: 5px;
  font-size: 14px;
  font-weight: 500;
}
p.my_tl_p02 {
  margin: 0;
  font-size: 11px;
  color: #9d81ff;
}
.my_t_col03 {
  width: 90%;
}
.my_t_row02 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
img.cal_svg {
  margin-left: 10px;
  height: 30px;
}
.my_t_row04 {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 15px;
}
.my_t_boxes {
  width: 33.33%;
  display: flex;
}
.my_t_box {
  background: #f9f9f9;
  border-radius: 10px;
  width: 70%;
  height: 50px;
  margin-bottom: 25px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
p.my_t_p02 {
  margin: 0;
  font-size: 10px;
  color: #b2b2b2;
  text-align: center;
}
p.my_t_p03 {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}
img.my_t_svg {
  width: 40px;
  height: 40px;
  margin-top: 14px;
}
img.my_t_qc_svg {
  width: 26px;
  height: 26px;
  margin: 20px 8px;
}
img.f_arrow_svg {
  margin-top: 55px;
}

.add_tl_pop {
  position: fixed;
  z-index: 11;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 660px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.add_pop_card {
  z-index: 10;
  background: #fff;
  box-shadow: 10px 15px 30px #00000029;
  border-radius: 10px;
  width: 60%;
  text-align: center;
  min-height: 350px;
  padding: 30px;
  margin-top: -250px;
}
p.add_pop_p1 {
  color: #9d81ff;
  font-size: 22px;
}
p.add_pop_p2 {
  width: 36%;
  margin: auto;
}
p.add_pop_p3 {
  width: 50%;
  margin: auto;
  padding: 20px;
  background: #f7f7f7;
  margin-top: 50px;
}
.add_pop_row1 {
  text-align-last: end;
  margin: 25px;
  position: absolute;
  bottom: 36%;
  right: 21%;
}
.add_pop_row2 {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}
.close_row {
  display: flex;
  width: 98%;
  justify-content: flex-end;
}
img.close_but {
  position: absolute;
  width: 4%;
  cursor: pointer;
}

.dots_card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 6px 9px 12px #dedede81;
  border: 1px solid #D5D5D534;
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  margin-left: -170px;
  margin-top: -20px;
  padding-right: 10px;

}

.dots_row1 {
  display: flex;
}
.dots_row2 {
  display: flex;
  margin-top: 10px;
}
img.dots_front {
  width: 10px;
  height: 10px;
  margin: 3px;
  margin-left: 15px;
}
p.dots_p1 {
  font-size: 10px;
  margin: 0;
  cursor: pointer;
}
p.dots_p2 {
  font-size: 10px;
  margin: 5px 0;
}
p.dots_p3 {
  background: #18AA1B33 0% 0% no-repeat padding-box;
  border: 1px solid #18AA1B;
  border-radius: 5px;
  padding: 4px 10px;
  font-size: 10px;
  margin: 0;
  color: #18AA1B;
  text-align: center;
  margin-left: 12px;
}