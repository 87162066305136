.threedots:after {
  content: "\2807";
  font-size: 50px;
  color: #9e81fb;
}
.dropdown-menu.show {
  font-size: 9px;
  box-shadow: 0px 0px 1px 1px lightgrey;
  background: white;
  padding: 10px;
  border-radius: 10px 0px;
  color:#673695;
  z-index:1002;
}
a.dropdown-item:hover{
  background: lightgreen;
  padding:2px 5px;
  border-radius: 2px;
}
