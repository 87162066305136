.navbar {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 6px #88888843;
    align-items: center;
    height: 80px;

}

.nav_contain1 {
    display: flex;
}
img.nav_blink {
    width: 25px;
    height: 25px;
    margin: 0 10px;
    margin-top: 15px;
}
.nav_contain4 {
    text-align: center;
}
p.nav_p2 {
    color: #8258ED;
    margin: 0;
    /* margin-top: 15px; */
}
p.nav_p3 {
    margin: 0;
    font-size: 11px;
}
h3.nav_h3 {
    margin: 0;
    /* margin-top: 15px; */
    color: #8258ED;
    font-size: 22px;
    font-weight: 500;
    
    
}
.nav_contain2 {
    text-align: center;
}
.nav_contain5 {
    display: flex;
    justify-content: space-between;
}
p.nav_p4 {
    margin: 0 10px;
    font-size: 11px;
}
img.nav_line {
    height: 18px;
}
img.nav_company {
    height: 55px;
    width: 100px;
    object-fit: contain;
    margin-right: 20px;
}