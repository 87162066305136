.up_train_inner_card {
    background: #fff;
    border: 1px dashed #a0a0a0;
    border-radius: 5px;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    align-content: center;
    align-items: center;
}

.update_train_card {
    background: #f7f7f7 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 400px;
    width: 90%;
}
.update_center {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
img.document_svg {
    width: 50px;
    height: 50px;
}