.first_Dash {
    width: 100%;
    height: 600px;
    display: flex;
}
.first_col1 {
    width: 15%;
    
}

.first_col2 {
    width: 100%;
}