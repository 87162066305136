td.day-cell {
    min-width: 41px !important;
    height: 10px !important;
}
.day-cell>div {
    height: 41px !important;
    text-align: right;
    padding: 1px !important;
    font-size:10px !important;
}

.day-cell>div>div {
    display: flex;
    flex-direction: column;
    height: 10px !important;
    justify-content: center;
    text-align: center;
    margin-top: 2px !important;
}
.days {
    height: 10px !important;
    text-align: center;
    color: black;
    font-size: 12px !important;
    padding-bottom: 15px;
    background-color: #f7f7f7 !important;
}