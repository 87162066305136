@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
html {
	height: 100%;
}
body {
	background-color: #f8f9fa;
	color: #333;
	font-family: "Poppins",sans-serif;
	font-size: 1rem;
	height: 100%;
	line-height: 1.6;
	overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Poppins",sans-serif;
	margin-top: 0;
	font-size: 20px;
	font-weight: 600;
}
p.lead.mb-0 {
    font-size: 18px;
}
a:hover,
a:active,
a:focus {
	outline: none;
	text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
hr {
    margin: 2rem auto;
}
.page-wrapper img {
	max-width: 100%;
}
a {
	color: #009ce7;
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}
.card {
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.875rem;
}
.card-body {
	padding: 1.5rem;
}
.card-header {
	border-bottom: 1px solid #e6e6e6;
	padding: 1rem 1.5rem;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #e6e6e6;
	padding: 1rem 1.5rem;
}
.card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
}
.card .card-header .card-title {
    margin-bottom: 0;
}
.sidebar {
    background-color: #fff;
    bottom: 0;
    left: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    transition: all 0.2s ease-in-out 0s;
    width: 280px;
    z-index: 1001;
}
.sidebar.opened {
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.sidebar-inner {
	height: 100%;
	min-height: 100%;
	transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu {
    padding: 15px;
}
.sidebar-menu ul {
    font-size: 15px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
}
.sidebar-menu li a {
	color: #333;
	display: block;
	font-size: 16px;
	height: auto;
	padding: 0 20px;
}
.sidebar-menu li a:hover {
    color: #ff7901;
}
.sidebar-menu > ul > li > a:hover {
    background-color: #e8e8e8;
    color: #000;
}
.sidebar-menu > ul > li > a.active:hover {
    background-color: #ee6800;
    color: #fff;
}
.mobile_btn {
	display: none;
	float: left;
}
.sidebar .sidebar-menu > ul > li > a span {
	transition: all 0.2s ease-in-out 0s;
	display: inline-block;
	margin-left: 10px;
	white-space: nowrap;
}
.sidebar-menu ul ul a {
    display: block;
    font-size: 15px;
    padding: 7px 10px 7px 45px;
    position: relative;
}
.sidebar-menu ul ul {
	display: none;
}
.sidebar-menu ul ul ul a {
	padding-left: 65px;
}
.sidebar-menu ul ul ul ul a {
	padding-left: 85px;
}
.sidebar-menu > ul > li {
    margin-bottom: 5px;
    position: relative;
}
.sidebar-menu > ul > li:last-child {
    margin-bottom: 0;
}
.sidebar-menu > ul > li > a {
    align-items: center;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    padding: 10px 20px;
    position: relative;
    transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu > ul > li > a.active {
	background-color: #ff7901;
	color: #fff;
}
.main-wrapper {
	width: 100%;
	height: 100vh;
	min-height: 100vh;
}
.page-wrapper {
	margin-left: 280px;
	padding-top: 60px;
	position: relative;
	transition: all 0.4s ease;
}
.page-wrapper > .content {
	padding: 1.875rem 1.875rem;
}
.page-header {
	margin-bottom: 1.875rem;
}
.page-title {
    color: #333;
    margin-bottom: 5px;
}
.main-wrapper > .header {
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.02);
    left: 310px;
    position: fixed;
    right: 50px;
    top: 50px;
    border-radius: 10px;
}
.page-wrapper {
	border-radius: 25px;
	background-color: #f5f5f5;
	padding-top: 0;
	min-height: calc(100vh - 40px);
}
.main-wrapper {
	background-color: #fff;
	height: auto;
	padding: 20px 20px 20px 0;
}
.card {
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.02);
}
.sidebar-logo {
    display: flex;
    padding: 20px 20px 0;
    justify-content: center;
    margin-bottom: 20px;
}
.sidebar-logo a{
	padding: 10px;
}
.sidebar-logo img {
	max-height: 46px;
	width: auto;
}
.text-primary {
	color: #ff7901 !important;
}
.widget {
    background: #FFF;
    border: 1px solid #F0F0F0;
        border-left-color: rgb(240, 240, 240);
        border-left-style: solid;
        border-left-width: 1px;
    border-left: 2px solid #ff7901;
    border-radius: 4px;
    padding: 1em;
    list-style: none;
    line-height: 2;
	margin-bottom: 30px;
}
@media only screen and (max-width: 991.98px) {
	.main-wrapper {
		padding: 20px;
	}
	.sidebar {
		margin-left: -225px;
		width: 225px;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
		z-index: 1041;
	}
	.page-wrapper {
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
	}
}

@media only screen and (max-width: 767.98px) {
	body {
		font-size: 0.9375rem;
	}
	h1, .h1 {
		font-size: 2rem;
	}
	h2, .h2 {
		font-size: 1.75rem;
	}
	h3, .h3 {
		font-size: 1.5rem;
	}
	h4, .h4 {
		font-size: 1.125rem;
	}
	h5, .h5 {
		font-size: 1rem;
	}
	h6, .h6 {
		font-size: 0.875rem;
	}
}

@media only screen and (max-width: 575.98px) {
	.card {
		margin-bottom: 0.9375rem;
	}
	.page-wrapper > .content {
		padding: 0.9375rem 0.9375rem 0;
	}
	.card-body {
		padding: 1.25rem;
	}
	.card-header {
		padding: .75rem 1.25rem;
	}
	.card-footer {
		padding: .75rem 1.25rem;
	}
	.page-header {
		margin-bottom: 0.9375rem;
	}
}