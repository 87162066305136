.heading-city-manager {
  color: #673695;
  font-size: 1.5em;
}

.property-box {
  height: 4em;
  opacity: 0.6;
}

.pic-container {
  display: flex;
  border: 1px solid #917ed3;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  height: 208px;
  width: 100%;
}

.need-opacity {
  opacity: 0.6;
}

.border-form {
  padding: 1%;
}

.documents-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-around;
  padding: 2%;
}

.border-box1 {
  border: 1px solid #dfdfd7;
}

.dotted-box {
  border: 1px solid #e5e7e9;
  padding: 2%;
}
/*# sourceMappingURL=form.css.map */

.addcm-container {
  margin: 22px;
  margin-top: 80px;
}

.myRowCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*----------------------------------------------------------------------------------------------------------------------*/

.documents-box {
  cursor: pointer;
}
img.image_upload_api {
  height: 90px;
}
.addcm_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.addcm_flex_col {
  display: flex;
  flex-direction: column;
}
.form-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.input-field {
  flex: 0 0 auto;
  width: 46%;
  margin-bottom: 25px;
}

.cm_label {
  margin-left: 10px;
  margin-bottom: 7px;
}

img.image_upload_profile {
  height: 200px;
  width: 200px;
  border-radius: 50%;
}
img.image_upload_api {
  height: 200px;
  width: 200px;
}
